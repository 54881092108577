import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { PeopleService } from '../../../shared/services/people.service';
import { Pool, TalentPoolQueryParams, TalentPoolResponse } from '../../../modules/client/dashboard/people/people.interface';
import { HeadingComponent } from "../heading/heading.component";
import { FormsModule } from '@angular/forms';
@Component({
    selector: 'app-add-to-talent-pool-list',
    standalone: true,
    templateUrl: './add-to-talent-pool-list.component.html',
    styleUrl: './add-to-talent-pool-list.component.scss',
    imports: [TableModule, FormsModule, CommonModule, ButtonModule, HeadingComponent,IconFieldModule,InputTextModule, InputIconModule]
})
export class AddToTalentPoolListComponent {
  @Input() firmId!: string;
  @Input() reviewerId!: string;

  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  expandedRows = {};
  viewMyTalentPool: boolean = false;
  totalTalentPoolCount: number = 0;
  talentPoolsData: any[] = [];
  rowExpandedData = [];
  searchText: string = '';
  constructor(
    private toast: ToastService, private peopleService:PeopleService
  ) {

  }


  onRowExpand(event: TableRowExpandEvent) {
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    this.rowExpandedData = [];
  }

  onSearchClick(){
    if(this.searchText){
      this.getTalentPools({first:0,rows:10});
    }
  }

  onEnter($event:any){
    if($event.keyCode === 13 && this.searchText){
      this.getTalentPools({first:0,rows:10});
    }
  }

  getTalentPools(event?: any) {
    this.rowExpandedData = [];
    const offset = event.first;
    const limit = event.rows;
    const queryParams: TalentPoolQueryParams = {
      offset: offset,
      limit: limit,
      firmId: this.firmId,
      reviewerId: this.reviewerId
    };
    if(this.searchText){
      queryParams.searchText = this.searchText;
    }
    this.peopleService.getAllUnAssignedTalentPoolisting(queryParams).subscribe((response: TalentPoolResponse) => {
      this.totalTalentPoolCount = response.totalCount;
      this.talentPoolsData = response.pools;
    })
  }

  assignTalentPoolToUser(poolsData: Pool) {
    this.peopleService.assignTalentPoolToUser({ reviewerId: this.reviewerId, talentPoolId: poolsData._id }).subscribe((response: any) => {
      this.toast.showSuccess(`Selected reviewers has been added to ${poolsData.name} `);
      this.getTalentPools({first:0,rows:10});
    })
  }

  backToProfile(){
    this.back.emit();
  }

}
